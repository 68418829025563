import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="V" transform="translate(-9.000000, -5.000000)">
        <path
          d="M63.146790,58.083878 
          C64.119072,55.555401 64.690475,53.212135 65.912750,51.280773 
          C66.899712,49.721241 68.671593,48.658451 70.095276,47.375301 
          C70.661316,47.820415 71.227356,48.265530 71.793388,48.710640 
          C68.922081,55.837433 66.152122,63.010284 62.976284,69.998688 
          C62.736698,70.525894 58.396839,70.302704 58.011871,69.475182 
          C54.875565,62.733421 52.227421,55.764565 50.169132,48.436188 
          C58.715328,50.367886 56.567631,59.377850 60.797871,64.300110 
          C61.504063,62.445911 62.265572,60.446468 63.146790,58.083878 
        z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
