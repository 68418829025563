import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-10.000000, 2.000000)">
      <g transform="translate(-5.000000, -12.000000)">
      <path fill="#010101" opacity="1.000000" stroke="none" 
	d="
M69.000000,121.000000 
	C46.000000,121.000000 23.500000,121.000000 1.000000,121.000000 
	C1.000000,81.000000 1.000000,41.000000 1.000000,1.000000 
	C41.000000,1.000000 81.000000,1.000000 121.000000,1.000000 
	C121.000000,41.000000 121.000000,81.000000 121.000000,121.000000 
	C103.833336,121.000000 86.666664,121.000000 69.000000,121.000000 
M55.282154,105.120041 
	C57.773529,106.405266 59.615246,109.265274 63.259087,107.105148 
	C75.239334,100.003067 87.414116,93.228798 99.380234,86.103683 
	C100.696960,85.319649 101.864296,83.094658 101.889404,81.518677 
	C102.101654,68.199409 101.960136,54.874874 102.104378,41.553864 
	C102.135376,38.691242 101.136131,37.137188 98.635811,35.742939 
	C86.745285,29.112474 75.010773,22.202126 63.114883,15.581572 
	C61.875477,14.891789 59.599720,14.851439 58.376137,15.533978 
	C46.474781,22.172798 34.652996,28.958904 22.937508,35.920086 
	C21.557783,36.739902 20.139284,38.850765 20.115438,40.384541 
	C19.898018,54.369892 19.926765,68.360641 20.079809,82.347664 
	C20.094475,83.687973 21.097120,85.594170 22.219824,86.256073 
	C32.937527,92.574791 43.780098,98.681725 55.282154,105.120041 
z"/>
<path fill="#E4E5E7" opacity="1.000000" stroke="none" 
	d="
M54.934486,104.984756 
	C43.780098,98.681725 32.937527,92.574791 22.219824,86.256073 
	C21.097120,85.594170 20.094475,83.687973 20.079809,82.347664 
	C19.926765,68.360641 19.898018,54.369892 20.115438,40.384541 
	C20.139284,38.850765 21.557783,36.739902 22.937508,35.920086 
	C34.652996,28.958904 46.474781,22.172798 58.376137,15.533978 
	C59.599720,14.851439 61.875477,14.891789 63.114883,15.581572 
	C75.010773,22.202126 86.745285,29.112474 98.635811,35.742939 
	C101.136131,37.137188 102.135376,38.691242 102.104378,41.553864 
	C101.960136,54.874874 102.101654,68.199409 101.889404,81.518677 
	C101.864296,83.094658 100.696960,85.319649 99.380234,86.103683 
	C87.414116,93.228798 75.239334,100.003067 63.259087,107.105148 
	C59.615246,109.265274 57.773529,106.405266 54.934486,104.984756 
M78.478706,27.973648 
	C73.434326,25.075504 68.475571,22.007710 63.289600,19.390081 
	C61.867535,18.672291 59.408554,18.656862 58.031178,19.416918 
	C47.266243,25.357176 36.583080,31.455883 26.057961,37.809315 
	C24.494617,38.753017 23.025360,41.267166 22.983904,43.088261 
	C22.711267,55.064632 22.713299,67.053032 22.977274,79.029770 
	C23.017942,80.874924 24.406055,83.443954 25.955465,84.380722 
	C36.475986,90.741379 47.161758,96.835831 57.925301,102.778740 
	C59.326496,103.552383 61.856071,103.653381 63.222660,102.901741 
	C74.142372,96.895714 84.967384,90.708412 95.650970,84.292809 
	C97.118301,83.411682 98.447189,80.985245 98.482819,79.246429 
	C98.728218,67.268883 98.769386,55.279861 98.457169,43.305454 
	C98.405952,41.340847 96.739838,38.759415 95.043144,37.620167 
	C89.950134,34.200478 84.472672,31.353376 78.478706,27.973648 
z"/>
<path fill="#040E1D" opacity="1.000000" stroke="none" 
	d="
M78.811966,28.128246 
	C84.472672,31.353376 89.950134,34.200478 95.043144,37.620167 
	C96.739838,38.759415 98.405952,41.340847 98.457169,43.305454 
	C98.769386,55.279861 98.728218,67.268883 98.482819,79.246429 
	C98.447189,80.985245 97.118301,83.411682 95.650970,84.292809 
	C84.967384,90.708412 74.142372,96.895714 63.222660,102.901741 
	C61.856071,103.653381 59.326496,103.552383 57.925301,102.778740 
	C47.161758,96.835831 36.475986,90.741379 25.955465,84.380722 
	C24.406055,83.443954 23.017942,80.874924 22.977274,79.029770 
	C22.713299,67.053032 22.711267,55.064632 22.983904,43.088261 
	C23.025360,41.267166 24.494617,38.753017 26.057961,37.809315 
	C36.583080,31.455883 47.266243,25.357176 58.031178,19.416918 
	C59.408554,18.656862 61.867535,18.672291 63.289600,19.390081 
	C68.475571,22.007710 73.434326,25.075504 78.811966,28.128246 
M63.027084,58.447029 
	C62.265572,60.446468 61.504063,62.445911 60.797871,64.300110 
	C56.567631,59.377850 58.715328,50.367886 50.169132,48.436188 
	C52.227421,55.764565 54.875565,62.733421 58.011871,69.475182 
	C58.396839,70.302704 62.736698,70.525894 62.976284,69.998688 
	C66.152122,63.010284 68.922081,55.837433 71.793388,48.710640 
	C71.227356,48.265530 70.661316,47.820415 70.095276,47.375301 
	C68.671593,48.658451 66.899712,49.721241 65.912750,51.280773 
	C64.690475,53.212135 64.119072,55.555401 63.027084,58.447029 
z"/>
<path fill="#EAEBED" opacity="1.000000" stroke="none" 
	d="
M63.146790,58.083878 
	C64.119072,55.555401 64.690475,53.212135 65.912750,51.280773 
	C66.899712,49.721241 68.671593,48.658451 70.095276,47.375301 
	C70.661316,47.820415 71.227356,48.265530 71.793388,48.710640 
	C68.922081,55.837433 66.152122,63.010284 62.976284,69.998688 
	C62.736698,70.525894 58.396839,70.302704 58.011871,69.475182 
	C54.875565,62.733421 52.227421,55.764565 50.169132,48.436188 
	C58.715328,50.367886 56.567631,59.377850 60.797871,64.300110 
	C61.504063,62.445911 62.265572,60.446468 63.146790,58.083878 
z"/>
      </g>
    </g>
  </svg>
);

export default IconLogo;
